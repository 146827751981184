<template>
   <div>
      <div id="content-page" class="content-page">

         <div class="container-fluid">
            <div class="row">


               <div class="col-md-6 col-lg-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Mês de Referencia</span></div>
                        <div class="justify-content-between align-items-center">
                           <div class="form-group">
                              <input type="month" class="form-control" id="referenceMonth" v-model="referenceMonth" placeholder="Mês de Referencia">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Embarcador</span></div>
                        <div class="justify-content-between align-items-center">
                           
                           <div class="form-group">
                              <select v-model="issuer" class="form-control" id="emissor">
                                 <option value="">Todos</option>
                                 <option
                                    v-for="participant in emissores"
                                    :key="participant.id"
                                    :value="participant.id"
                                 >
                                    {{ participant.company_name }}
                                 </option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-6 col-lg-4">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Transportadora</span></div>
                        <div class="justify-content-between align-items-center">
                           
                           <div class="form-group">
                              <select v-model="transporter" class="form-control" id="emissor">
                                 <option value="">Todos</option>
                                 <option
                                    v-for="participant in transportadores"
                                    :key="participant.id"
                                    :value="participant.id"
                                 >
                                    {{ participant.company_name }}
                                 </option>
                              </select>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row">
               <div class="col-md-6 col-lg-3">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Último mês</span></div>
                        <div class="d-flex justify-content-between align-items-center">
                           <div class="value-box">
                              <h2 class="mb-0 titulo-especifico"><span class="counter"><b>{{ lastMonthInvoicesCount
                                       }}</b></span></h2>
                              <p class="mb-0 text-secondary line-height">{{ lastMonthPercentChange }}%</p>
                           </div>
                           <div
                              :class="{ 'iq-iconbox': true, 'iq-bg-success': lastMonthPercentChange >= 0, 'iq-bg-danger': lastMonthPercentChange < 0 }">
                              <i
                                 :class="{ 'ri-arrow-up-line': lastMonthPercentChange >= 0, 'ri-arrow-down-line': lastMonthPercentChange < 0 }"></i>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>

               <div class="col-md-6 col-lg-3">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Taxa de agendamento</span></div>
                        <div class="d-flex justify-content-between align-items-center">
                           <div class="value-box">
                              <h2 class="mb-0 titulo-especifico"><span class="counter"><b>{{ appointmentRateCount
                                       }}</b></span></h2>
                              <p class="mb-0 pl-2 text-secondary line-height"> {{ appointmentRatePercent }}% </p>
                           </div>
                           <div
                              :class="{ 'iq-iconbox': true, 'iq-bg-success': appointmentRatePercent >= 0, 'iq-bg-danger': appointmentRatePercent < 0 }">
                              <i
                                 :class="{ 'ri-arrow-up-line': lastWeekPercentappointmentRatePercentChange >= 0, 'ri-arrow-down-line': appointmentRatePercent < 0 }"></i>
                           </div>
                        </div>
                        <div class="iq-progress-bar mt-5">
                           <span class="bg-info" data-percent="50"></span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-3">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Ultima semana</span></div>
                        <div class="d-flex justify-content-between align-items-center">
                           <div class="value-box">
                              <h2 class="mb-0 titulo-especifico"><span class="counter"><b>{{ lastWeekCount }}</b></span>
                              </h2>
                              <p class="mb-0 text-secondary line-height">{{ lastWeekPercentChange }}%</p>
                           </div>
                           <div
                              :class="{ 'iq-iconbox': true, 'iq-bg-success': lastWeekPercentChange >= 0, 'iq-bg-danger': lastWeekPercentChange < 0 }">
                              <i
                                 :class="{ 'ri-arrow-up-line': lastWeekPercentChange >= 0, 'ri-arrow-down-line': lastWeekPercentChange < 0 }"></i>
                           </div>
                        </div>
                        <div class="iq-progress-bar mt-5">
                           <span class="bg-success" data-percent="66"></span>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-md-6 col-lg-3">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-body">
                        <div class="text-center"><span>Média dias retorno</span></div>
                        <div class="d-flex justify-content-between align-items-center">
                           <div class="value-box">
                              <h2 class="mb-0 titulo-especifico"><span class="counter"><b>7.55</b></span></h2>
                              <p class="mb-0 pl-2 text-secondary line-height"> 25.5% </p>
                           </div>
                           <div class="iq-iconbox iq-bg-primary">
                              <i class="ri-arrow-up-line"></i>
                           </div>
                        </div>
                        <div class="iq-progress-bar mt-5">
                           <span class="bg-primary" data-percent="70"></span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div class="row">
               <!-- Gráfico de Barras -->
               <div class="col-lg-6">
                  <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h2 class="mb-0 titulo-especifico"><span class="counter"><b>Notas de remessa por
                                    status</b></span></h2>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <canvas id="barChart"></canvas>
                     </div>
                  </div>
               </div>

               <!-- Gráficos de Pizza -->
               <div class="col-lg-3">
                  <!-- Primeiro Gráfico de Pizza -->
                  <div class="iq-card iq-card-stretch">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h2 class="mb-0 titulo-especifico"><span class="counter"><b>Notas entregues</b></span></h2>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <canvas id="pieChart1"></canvas>
                        <div id="pieChart1-legend" class="chart-legend"></div>
                     </div>
                  </div>
                  <!-- Segundo Gráfico de Pizza -->
                  <div class="iq-card iq-card-stretch ">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h2 class="mb-0 titulo-especifico"><span class="counter"><b>Notas coletadas</b></span></h2>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <canvas id="pieChart2"></canvas>
                        <div id="pieChart2-legend" class="chart-legend"></div>
                     </div>
                  </div>
                  
               </div>

               <!-- Terceiro Gráfico de Pizza e Percentuais -->
               <div class="col-lg-3">
                  
                  
                  <div class="iq-card iq-card-stretch">
                     <div class="iq-card-header d-flex justify-content-between">
                        <div class="iq-header-title">
                           <h2 class="mb-0 titulo-especifico"><span class="counter"><b>Notas agendadas</b></span></h2>
                        </div>
                     </div>
                     <div class="iq-card-body">
                        <canvas id="pieChart3"></canvas>
                        <div id="pieChart3-legend" class="chart-legend"></div>
                     </div>
                  </div>
                  <!-- Percentuais -->
                  <div class="iq-card iq-card-stretch" style="height: 45.4%;">
                     <div class="iq-card-header d-flex justify-content-between">
                           <div class="iq-header-title">
                              <h2 class="mb-0 titulo-especifico"><span class="counter"><b>Desempenho</b></span></h2>
                           </div>
                        </div>
                     <div class="iq-card-body" style="padding-top: 49px;">
                        
                        <div class="performance-metric">
                           <span>Entrega</span>
                           <span style="color: #28a745;">{{ deliveryRate }}%</span>
                        </div>
                        <div class="performance-metric">
                           <span>Coleta</span>
                           <span style="color: #ffc107;">{{ collectionRate }}%</span>
                        </div>
                        <div class="performance-metric">
                           <span>Retorno</span>
                           <span style="color: #dc3545;">{{ returnRate }}%</span>
                        </div>
                     </div>
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script setup>
</script>
<script>
import axios from 'axios';
import { toast } from 'vue3-toastify';
import Chart from 'chart.js/auto';

export default {
   data() {
      return {
         pieCharts: {},
         dashboardData: {},
         deliveryRate: 0,
         collectionRate: 0,
         returnRate: 0,
         lastMonthInvoicesCount: 0,
         lastMonthPercentChange: 0,
         appointmentRateCount: 0,
         appointmentRatePercent: 0,
         lastWeekCount: 0,
         lastWeekPercentChange: 0,
         averageReturnDays: 0,
         barChart: null,
         pieChart1: null,
         pieChart2: null,
         pieChart3: null,
         referenceMonth: '',
         issuer: '',
         transporter: '',
         emissores: [],
         transportadores: [],
         lastWeekPercentappointmentRatePercentChange: 0,
      };
   },
   async mounted() {
      const dateObj = new Date();
      const month   = dateObj.getUTCMonth() + 1; // months from 1-12
      const year    = dateObj.getUTCFullYear();

      this.referenceMonth = localStorage.getItem('referenceMonth') ?? year + '-' + month.toString().padStart(2,"0")
      this.issuer = localStorage.getItem('issuer') ?? ''
      this.transporter = localStorage.getItem('transporter') ?? ''

      const authHeader = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      axios
        .get("/api/participants/interest-type/Embarcador", authHeader)
        .then((response) => {
          this.emissores = response.data.data;
        });

      axios
        .get("/api/participants/interest-type/Transportadora", authHeader)
        .then((response) => {
          this.transportadores = response.data.data;
        });


      await this.fetchDashboardData();

   },
   watch:{

      referenceMonth(referenceMonth){
         let oldValue = localStorage.getItem('referenceMonth')

         localStorage.setItem('referenceMonth',referenceMonth)
         if(referenceMonth != oldValue){
            location.reload()
         }
      },
      issuer(issuer){
         let oldValue = localStorage.getItem('issuer')
         localStorage.setItem('issuer',issuer)

         if(issuer != oldValue){
            location.reload()
         }

      },
      transporter(transporter){
         let oldValue = localStorage.getItem('transporter')

         localStorage.setItem('transporter',transporter)

         if(transporter != oldValue){
            location.reload()
         }
      },
   },
   methods: {
      
      async fetchDashboardData(isNotFirstInteraction= false) {
         try {

            let params = {
               referenceMonth: localStorage.getItem('referenceMonth')??this.referenceMonth,
               issuer: localStorage.getItem('issuer')??this.issuer,
               transporter:  localStorage.getItem('transporter')??this.transporter,
            };
            let urlParams = new URLSearchParams(params).toString();
            const response = await axios.get('/api/dashboard/statistics?' + urlParams, {
               headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`, // Adapte conforme necessário
               },
            });
            this.dashboardData = response.data;
            this.updateData(); // Atualizar os dados reativos
            this.$nextTick(() => {
               this.initializeCharts(isNotFirstInteraction); // Inicializar gráficos após a atualização dos dados reativos
            });
         } catch (error) {
            console.error('Erro ao buscar dados do dashboard:', error);
            toast.error('Ocorreu um erro ao buscar os dados do dashboard.');
         }
      },
      updateData() {
         this.deliveryRate = this.dashboardData.performanceMetrics.deliveryRate;
         this.collectionRate = this.dashboardData.performanceMetrics.collectionRate;
         this.returnRate = this.dashboardData.performanceMetrics.returnRate;
         this.lastMonthInvoicesCount = this.dashboardData.lastMonthInvoices.count;
         this.lastMonthPercentChange = this.dashboardData.lastMonthInvoices.percentChange;
         this.appointmentRateCount = this.dashboardData.appointmentRate.count;
         this.appointmentRatePercent = this.dashboardData.appointmentRate.appointmentRate;
         this.lastWeekCount = this.dashboardData.lastWeekData.count;
         this.lastWeekPercentChange = this.dashboardData.lastWeekData.percentChange;
         this.averageReturnDays = this.dashboardData.averageReturnDays.average_days;
      },
      initializeCharts(isNotFirstInteraction) {
         this.initBarChart(isNotFirstInteraction);
         this.initPieChart('pieChart1', this.dashboardData.deliveryChartData, ['Sem agenda', 'Agendadas']);
         this.initPieChart3('pieChart2', this.dashboardData.collectionChartData, ['Entregues', 'Em Trânsito', 'Retorno']);
         this.initPieChart2('pieChart3', this.dashboardData.scheduledChartData, ['Coletadas', 'Em Coleta']);
      },
      initPieChart1(elementId, chartData, labels) {
         if (this[elementId]) {
            this[elementId].destroy();
         }
         const ctx = document.getElementById(elementId).getContext('2d');
         const data = {
            labels: labels,
            datasets: [{
               data: [chartData.entregues, chartData.emTransito],
               backgroundColor: [
               'rgba(86, 36, 148, 0.3)',  // Antes: 0.2
               'rgba(86, 36, 148, 0.5)',  // Antes: 0.4
               'rgba(86, 36, 148, 0.7)',  // Antes: 0.6
               'rgba(86, 36, 148, 0.9)',  // Antes: 0.8
               'rgba(86, 36, 148, 1)'     // Antes: sem alpha
               ],
               borderColor: [
               'rgba(86, 36, 148, 1)',
               ],

               borderWidth: 1
            }]
         };
         this[elementId] = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
               maintainAspectRatio: false,
               responsive: true,
               legend: {
                  position: 'bottom'
               }
            }
         });
      },
      initPieChart2(elementId, chartData, labels) {
         if (this[elementId]) {
            this[elementId].destroy(); // Destrua o gráfico anterior se existir
         }
         const ctx = document.getElementById(elementId).getContext('2d');
         const data = {
            labels: labels,
            datasets: [{
               data: [chartData.coletadas, chartData.emColeta], // Isso precisa corresponder aos dados da sua API
               backgroundColor: [
               'rgba(86, 36, 148, 0.3)',  // Antes: 0.2
               'rgba(86, 36, 148, 0.5)',  // Antes: 0.4
               'rgba(86, 36, 148, 0.7)',  // Antes: 0.6
               'rgba(86, 36, 148, 0.9)',  // Antes: 0.8
               'rgba(86, 36, 148, 1)'     // Antes: sem alpha
               ],
               borderColor: [
               'rgba(86, 36, 148, 1)',
               ],

               borderWidth: 1
            }]
         };
         this[elementId] = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
               maintainAspectRatio: false,
               responsive: true,
               legend: {
                  position: 'bottom'
               }
            }
         });
      },
      initPieChart3(elementId, chartData, labels) {
         if (this[elementId]) {
            this[elementId].destroy(); // Destrua o gráfico anterior se existir
         }
         const ctx = document.getElementById(elementId).getContext('2d');
         const data = {
            labels: labels,
            datasets: [{
               data: [chartData.entregues, chartData.emTransito], // Isso precisa corresponder aos dados da sua API
               backgroundColor: [
               'rgba(86, 36, 148, 0.3)',  // Antes: 0.2
               'rgba(86, 36, 148, 0.5)',  // Antes: 0.4
               'rgba(86, 36, 148, 0.7)',  // Antes: 0.6
               'rgba(86, 36, 148, 0.9)',  // Antes: 0.8
               'rgba(86, 36, 148, 1)'     // Antes: sem alpha
               ],
               borderColor: [
               'rgba(86, 36, 148, 1)',
               ],

               borderWidth: 1
            }]
         };
         this[elementId] = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
               maintainAspectRatio: false,
               responsive: true,
               legend: {
                  position: 'bottom'
               }
            }
         });
      },
      initBarChart(isNotFirstInteraction= false) {
         if(isNotFirstInteraction){
            return;
         }
    const barChartCtx = document.getElementById('barChart').getContext('2d');
    if (this.barChart) {
        this.barChart.destroy(); // Destruir o gráfico existente
    }

    if (barChartCtx && Array.isArray(this.dashboardData.statusChartData)) {
         new Chart(barChartCtx, {
            type: 'bar',
            data: {
                labels: this.dashboardData.statusChartData.map(item => item.status_id),
                datasets: [{
                    label: 'Quantidade de notas por status',
                    data: this.dashboardData.statusChartData.map(item => item.total),
                    backgroundColor: [
                  'rgba(86, 36, 148, 0.3)',  // Antes: 0.2
                  'rgba(86, 36, 148, 0.5)',  // Antes: 0.4
                  'rgba(86, 36, 148, 0.7)',  // Antes: 0.6
                  'rgba(86, 36, 148, 0.9)',  // Antes: 0.8
                  'rgba(86, 36, 148, 1)'     // Antes: sem alpha
                  ],
                  borderColor: [
                  'rgba(86, 36, 148, 1)',
                  ],

                    borderWidth: 1
                }]
            },
            options: {
                scales: {
                    y: { beginAtZero: true }
                },
                maintainAspectRatio: false,
                responsive: true,
            }
        });
    }
},

      initPieChart(elementId, chartData, labels) {
         console.log(this[elementId])
         if (this[elementId]) {
            this[elementId].destroy(); // Destrua o gráfico anterior se existir
         }
         
         const ctx = document.getElementById(elementId).getContext('2d');
         const data = {
            labels: labels,
            datasets: [{
               data: [chartData.entregues, chartData.naoEntregues], // Isso precisa corresponder aos dados da sua API
               backgroundColor: [
  'rgba(86, 36, 148, 0.3)',  // Antes: 0.2
  'rgba(86, 36, 148, 0.5)',  // Antes: 0.4
  'rgba(86, 36, 148, 0.7)',  // Antes: 0.6
  'rgba(86, 36, 148, 0.9)',  // Antes: 0.8
  'rgba(86, 36, 148, 1)'     // Antes: sem alpha
],
borderColor: [
  'rgba(86, 36, 148, 1)',
],

               borderWidth: 1
            }]
         };
         this[elementId] = new Chart(ctx, {
            type: 'pie',
            data: data,
            options: {
               maintainAspectRatio: false,
               responsive: true,
               legend: {
                  position: 'bottom'
               }
            }
         });
      },
      initSinglePieChart(elementId, chartData) {
         const ctx = document.getElementById(elementId).getContext('2d');
         if (this.pieCharts[elementId]) {
            this.pieCharts[elementId].destroy(); // Destruir o gráfico de pizza existente
         }
         if (ctx && chartData) { // Verifique se o contexto e os dados existem
            new Chart(ctx, {
               type: 'pie',
               data: {
                  labels: chartData.map(item => item.label),
                  datasets: [{
                     data: chartData.map(item => item.value),
                     backgroundColor: chartData.map(item => item.color || 'rgba(86, 36, 148, 0.8)'), // Cor padrão se não fornecida
                     borderColor: 'rgba(255, 255, 255, 1)', // Branco ou qualquer outra cor para a borda
                     borderWidth: 1
                  }]
               },
               options: {
                  maintainAspectRatio: false,
                  responsive: true,
                  legend: {
                     position: 'bottom'
                  }
               }
            });
         }
      }

   },
};
</script>


<style>
.iq-card-header {
   background-color: #f7f7f7;
   /* Ou qualquer outra cor de fundo desejada */
   border-bottom: none;
   justify-content: center;
}

.iq-card-header .iq-header-title {
   width: 100%;
   /* Faz o título ocupar a largura total */
   display: flex;
   justify-content: center;
   /* Centraliza o título dentro do contêiner */
}

.iq-card-title {
   font-size: 18px;
   /* Ajuste conforme a necessidade */
   color: #333;
   /* Cor do título */
}

/* As classes abaixo ajustam os gráficos de pizza e a legenda */
.chartjs-render-monitor {
   max-width: 100%;
   margin-bottom: 10px;
   /* Espaço entre o gráfico e a legenda */
}

.chart-legend {
   text-align: center;
   font-size: 12px;
   padding-top: 10px;
}

.iq-card-body {
   /* Adicione um padding para evitar que os gráficos de pizza fiquem muito grandes */
   padding: 10px;
}

#barChart {
   /* Defina a altura que você deseja para o gráfico de barras */
   height: 555px !important;
}

#pieChart1,
#pieChart2,
#pieChart3 {
   /* Defina uma altura menor para os gráficos de pizza */
   height: 210px !important;
}

.performance-metric {
   display: flex;
   justify-content: space-between;
   margin-bottom: 0.5rem;
   font-size: 25px;
}

.performance-metric span {
   font-weight: bold;
   font-size: 16px;
}

.chart-container {
   position: relative;
   margin: auto;
}

/* Chart.js legenda customizada embaixo do gráfico */
#pieChart1-legend,
#pieChart2-legend,
#pieChart3-legend {
   display: flex;
   justify-content: center;
   flex-wrap: wrap;
   font-size: 12px;
}

.legend-item {
   display: flex;
   align-items: center;
   margin-right: 10px;
}

.legend-color-box {
   width: 14px;
   height: 14px;
   display: inline-block;
   margin-right: 5px;
}

.iq-header-title h4 {
   text-align: center;
   margin: 0 auto;
}

/* .iq-card-header, */
/* .iq-card-title, */
.performance-metric span {
   color: #5624B2;
   /* Cor hexadecimal aplicada */
   font-size: 30px;
}

.titulo-especifico {
   text-align: center;
}

.value-box {
   display: flex;
   justify-content: center;
   width: 100%;
}
</style>