<template>
  <div class="wrapper">
    <div class="iq-top-navbar">
      <div class="iq-navbar-custom">
        <div class="iq-sidebar-logo">
          <div class="top-logo">
            <a href="/"  class="logo"s>
              <div class="iq-light-logo">
                <img src="@/assets/images/logo.gif" class="img-fluid" alt="" />
              </div>
              <div class="iq-dark-logo">
                <img src="@/assets/images/logo-dark.gif" class="img-fluid" alt="" />
              </div>
              <span>vito</span>
            </a>
          </div>
        </div>
        <nav class="navbar navbar-light p-0">
          <ul class="navbar-list col-12">
            <li class="float-right cursor-pointer">
              
              <a
                target="_self"
                class="search-toggle iq-waves-effect d-flex align-items-center bg-primary rounded"

                style="cursor: pointer; background-color: var(--iq-primary) !important;"
              >
                <img
                  src="@/assets/images/foto_login.png"
                  class="img-fluid rounded mr-3"
                  alt="usuário"
                />
                <div class="caption">
                  <h6 class="mb-0 line-height text-white">{{ userName }}</h6>
                  <span class="font-size-12 text-white">Disponível</span>
                </div>
                <form  style="margin-left: 20px;">
                <a class="btn btn-primary dark-btn-primary" @click.prevent="logout" role="button" style=" background-color: var(--iq-primary-shadow-1); border-color: var(--iq-primary-shadow-1);">Sair <i class="ri-login-box-line ml-2"></i></a>
              </form>
              </a>
              
              
              <div class="iq-sub-dropdown iq-user-dropdown">
                <div class="iq-card shadow-none m-0">
                  <div class="iq-card-body p-0">
                    <div class="bg-primary p-3">
                      <h5 class="mb-0 text-white line-height">Olá, {{ userName }}</h5>
                      <span class="text-white font-size-12">Disponível</span>
                    </div>
                    <a href="/users" class="iq-sub-card iq-bg-primary-hover">
                      <div class="media align-items-center">
                        <div class="rounded iq-card-icon iq-bg-primary">
                          <i class="ri-profile-line"></i>
                        </div>
                        <div class="media-body ml-3">
                          <h6 class="mb-0">Editar Perfil</h6>
                          <p class="mb-0 font-size-12">Modificar seus detalhes pessoais.</p>
                        </div>
                      </div>
                    </a>

                    
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="iq-sidebar">
      <div class="iq-sidebar-logo d-flex justify-content-between">
        <a href="/" @click="collapseAllMenu()">
          <div class="iq-light-logo" style="padding: 20px;">
            <img src="@/assets/images/2backPrincipal.png" class="img-fluid" style="height: auto;" alt="" />
          </div>
          <div class="iq-dark-logo">
            <img src="@/assets/images/logo-dark.gif" class="img-fluid" alt="" />
          </div>
        </a>
        <div class="iq-menu-bt-sidebar">
          <div class="iq-menu-bt align-self-center">
            <div class="wrapper-menu">
              <div class="main-circle"><i class="ri-arrow-left-s-line"></i></div>
              <div class="hover-circle"><i class="ri-arrow-right-s-line"></i></div>
            </div>
          </div>
        </div>
      </div>
      <div id="sidebar-scrollbar">
  <nav class="iq-sidebar-menu">
    <ul id="iq-sidebar-toggle" class="iq-menu">
      <template v-for="(itemMenu, indexMenu) in menu">
        <li v-if="!itemMenu.hasSubmenu" :class="($route.name == itemMenu.route + '-index') ? 'nav-item active' : 'nav-item'">
          <a :href="'/'+ itemMenu.route" @click="collapseAllMenu()" class=""><span>{{ itemMenu.title }}</span></a>
        </li>
        <li v-else>
          <a :href="'#menu-design-' + indexMenu" class="iq-waves-effect collapse" data-toggle="collapse" :aria-expanded="itemMenu.expanded"><span>{{ itemMenu.title }}</span><i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
          <ul :class="(itemMenu.expanded) ? 'iq-submenu collapse show' : 'iq-submenu collapse'" data-parent="#iq-sidebar-toggle" :id="'menu-design-' + indexMenu">
            <template v-for="(submenuItem, indexSubmenu) in itemMenu.submenus">
              <li v-if="!submenuItem.submenus" :class="this.$route.name.includes(submenuItem.route) ? 'active' : ''">
                <a class="nav-link" :href="'/' + submenuItem.route" @click="setActiveMenu(indexMenu)"><i :class="submenuItem.icon" style="color: #8360a8; font-size: 2em;"></i>{{ submenuItem.title }}</a>
              </li>
              <li v-else>
                <a :href="'#submenu-design-' + indexMenu + '-' + indexSubmenu" class="iq-waves-effect collapse" data-toggle="collapse" :aria-expanded="false"><i :class="submenuItem.icon"></i>{{ submenuItem.title }}<i class="ri-arrow-right-s-line iq-arrow-right"></i></a>
                <ul :class="'iq-submenu collapse'" :id="'submenu-design-' + indexMenu + '-' + indexSubmenu">
                  <li v-for="(submenuItem, indexSubmenu) in itemMenu.submenus" :class="isRouteActive(submenuItem.route) ? 'active' : ''">
                  <a :href="'/' + submenuItem.route" @click="setActiveMenu(indexMenu)">
                    <i :class="submenuItem.icon"></i>{{ submenuItem.title }}
                  </a>
                </li>
                </ul>
              </li>
            </template>
          </ul>
        </li>
      </template>
    </ul>
  </nav>
  <div class="p-3"></div>
</div>
    </div>
  </div>
</template>

<script>
import router from '@/router'
import { core } from '@/config/pluginInit'
import axios from 'axios'
import { toast } from 'vue3-toastify'
export default {
  data() {
    return {
      menu: [
        
        {
          title:'Dashboard',
          icon:'ri-inbox-archive-line',
          route:'',
        },

        {
          title:'Relatorios',
          hasSubmenu:false,
          route:'reports',
        },
        {
          title:'Cadastros',
          hasSubmenu:true,
          expanded:false,
          submenus:[
            {
              title:'Usuários',
              icon:'ri-user-line',
              route:'users',
            },
            {
              title:'Grupos',
              icon:'ri-group-line',
              route:'groups',
            },
            {
              title:'Grupo de permissão',
              icon:'ri-group-line',
              route:'group-permissions',
            },

            {
              title:'Permissões',
              icon:'ri-group-line',
              route:'permissions',
            },
            {
              title:'Participantes',
              icon:'ri-group-fill',
              route:'participants',
            },
            {
              title:'Ativos',
              icon:'ri-inbox-archive-line',
              route:'assets',
            },
            {
              title:'Veículos',
              icon:'ri-car-line',
              route:'vehicles',
            },
            {
              title:'Prazos',
              icon:'ri-timer-line',
              route:'deadlines',
            },
            {
              title:'Feriados',
              icon:'ri-calendar-2-line',
              route:'holidays',
            },
            {
              title:'CFOP',
              icon:'ri-wallet-line',
              route:'cfop',
            },
            {
              title:'NCM',
              icon:'ri-bar-chart-fill',
              route:'ncms',
            },
            {
              title:'Tipo de ocorrências',
              icon:'ri-alert-line',
              route:'occurrence-types',
            },
            {
              title:'Ocorrências',
              icon:'ri-alert-line',
              route:'occurrences',
            },
          ],
        },//cadastros
        {
          "title": "Notas Fiscais",
          "expanded": false,
          "hasSubmenu": true,
          "route": "invoices",
          "submenus": [
            {
              "title": "Listagem",
              "icon": "ri-list-check",
              "route": "invoices"
            },
            {
              "title": "Upload de Notas Fiscais",
              "icon": "ri-file-upload-line",
              "route": "invoices/create"
            },
          ]
        },
        {
          "title": "Vale Ativos",
          "expanded": false,
          "hasSubmenu": true,
          "submenus": [
          {
            "title": "Listagem",
            "route": "active-vouchers",
            "icon" : "ri-file-list-line"
          },
          {
            "title": "Upload Vales",
            "route": "active-vouchers/create",
            "icon" : "ri-file-upload-line"
          },
        ]
        },

        {
          title:'Processo',
          hasSubmenu:false,
          route:'process',
        },
        {
          "title": "Parâmetros",
          "icon": "ri-settings-3-line",
          "expanded": false,
          "hasSubmenu": true,
          "submenus": [

          {
            title:'Tipo de interesse',
            icon:'ri-user-line',
            route:'interest-types',
          },
          {
            "title": "Status",
            "icon": "ri-file-paper-line",
            "route": "invoice-statuses"
          },
          {
            "title": "Capacidades",
            "icon": "ri-stack-line",
            "route": "capacities"
          },
          {
            "title": "Faixa KM",
            "icon": "ri-roadster-line",
            "route": "km-ranges"
          },
          {
            "title": "Limites",
            "icon": "ri-stop-circle-line",
            "route": "limits"
          },
          {
            "title": "Motivos",
            "icon": "ri-question-line",
            "route": "reasons"
          }
              
              
          ]
          },
        {
          title:'EDI',
          hasSubmenu:false,
          route:'',
        },

        {
          title:'Cte',
          hasSubmenu:false,
          route:'',
        },
      ],
      userName: null,
      indexActiveMenu: null,
    }
  },
  mounted() {

    core.index()
    this.userName = localStorage.getItem('name')
    this.indexActiveMenu = localStorage.getItem('indexActiveMenu',undefined)
    if(this.indexActiveMenu != undefined){
      this.menu[this.indexActiveMenu].expanded = true
    }

  },
  methods: {
    setActiveMenu(indexActiveMenu){
      core.startLoader()
      console.log('rota' + indexActiveMenu)
      localStorage.setItem('indexActiveMenu',indexActiveMenu)
    },
    isRouteActive(routePath) {
      console.log('rota' + routePath)
    return this.$route.path.startsWith('/' + routePath);
  },
    collapseAllMenu(){
      core.startLoader()
      localStorage.removeItem('indexActiveMenu');
      this.menu.map((menu) =>{
        let newMenu = menu;
        if(newMenu.hasSubmenu){
          newMenu.expanded = false
        }
        return newMenu;
      });
    },
    logout() {
      try {
        axios.post('/logout', this.userData, {
          headers: {
            Authorization: `Bearer ` + localStorage.getItem('token')
          }
        })
        
          .then((response) => {
            if (response.status === 200) {
              localStorage.removeItem('token');
              localStorage.removeItem('name');
              toast.success('Deslogado com sucesso!');
              this.$router.push('/auth');
            }
          })
          .catch((e) => {
            // Mostra uma mensagem de erro
            localStorage.removeItem('token');
              localStorage.removeItem('name');
            toast.success('Deslogado com sucesso!');
            this.$router.push('/auth');
            // Limpa o token do localStorage mesmo se der erro
            
          });
      } catch (e) {
        // Tratamento de erro, se necessário
      }
    }
  }
}

</script>
<style>
.active i {
  color: #FFFFFF !important;
}
</style>