<template>
  <!-- Sign in Start -->
  <section class="sign-in-page">
    <div class="container bg-white mt-5 p-0">
      <div class="row no-gutters">
        <div class="col-md-6 align-self-center">
          <div class="sign-in-from">
            
            <img src="@/assets/images/2backPrincipal.png" class="img-fluid mb-4" alt="2back logo" />
            <h1 class="mb-0">Entrar</h1>
            <p>Informe seu endereço de e-mail e senha para acessar o painel de administração.</p>
            <form class="mt-4" @submit.prevent="authenticate">
              <div class="form-group">
                <label for="exampleInputEmail1">Endereço de E-mail</label>
                <input v-model="userData.email" type="email" name="email" class="form-control mb-0"
                  placeholder="Informe o endereço de e-mail" required />
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">Senha</label>
                <a href="#" class="float-right">Esqueceu a senha?</a>
                <input v-model="userData.password" type="password" class="form-control mb-0" id="exampleInputPassword1"
                  placeholder="Senha" required />
              </div>
              <div class="d-inline-block w-100">
                <div class="custom-control custom-checkbox d-inline-block mt-2 pt-1">
                  <input type="checkbox" class="custom-control-input" id="customCheck1" name="remember" />
                  <label class="custom-control-label" for="customCheck1">Lembrar-me</label>
                </div>
                <button type="submit" class="btn btn-primary float-right">Entrar</button>
              </div>
            </form>
          </div>
        </div>
        <div class="col-sm-6 text-center custom-sign-in-detail justify-center align-center">
          <div class="signs-in-detail text-white justify-center align-center">
            <a class="sign-in-logo mb-5" href="#">
              <img src="" class="img-fluid" alt="" />
            </a>
            <v-carousel :show-arrows="false" show-arrows="hover" cycle hide-delimiter-background show-arrows-on-hover>
              <v-carousel-item cover v-for="(item, i) in items" :key="i">
                <v-sheet class="d-flex justify-center align-center" style="background-color: transparent;">
                  <div class="text-center">
                    <h4 class="mb-1 text-white">{{ item.title }}</h4>
                    <img :src="item.image" class="img-fluid mb-4" style="width: 35%; height: auto;" :alt="`Slide ${i + 1}`" />

                    <p class="mb-1 text-white">{{ item.description }}</p>
                    <p> </p>
                    <p> </p>
                    <p></p>
                    <p></p>
                  </div>
                </v-sheet>
              </v-carousel-item>
            </v-carousel>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import axios from 'axios'
import logo from '@/assets/images/logo-white.png'
import bgImage from '@/assets/images/login/login-2.jpg'

import { toast } from 'vue3-toastify'
import router from '@/router'

export default {
  components: {},
  data() {
    return {
      userData: {
        email: '',
        pass: ''
      },
      items: [
        {
          image: require('@/assets/images/net4cargo_logo-white.png'),
          title: "",
          description: "Desbloqueando processos, conectando possibilidades."
        },
      ]
    }
  },
  methods: {
    authenticate() {
      try {
        axios
          .post('/api/login', this.userData)
          .then((response) => {
            if (response.status == 200) {
              //guardar na sessao
              localStorage.setItem('token', response.data.token)
              localStorage.setItem('name', response.data.name)
              toast.success('Logado com sucesso!')
              router.push('/')
            } else {
              toast.error('Usuario e/ou senha invalidos!')
              localStorage.removeItem('token')
            }
          })
          .catch((e) => {
            toast.error('Usuario e/ou senha invalidos!')
            localStorage.removeItem('token')
          })
      } catch (e) { }
    }
  }
}
</script>

<style>
.text-white p {
  font-size: 2.0rem;
}

.custom-sign-in-detail {
  padding-bottom: 0;
}

.signs-in-detail {
  background-image: url('@/assets/images/background_image.jpg');
  /* Verifique o caminho */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 20px;
  margin: 100px auto;
  margin-top: 50px;
  margin-bottom: 50px;
  max-height: calc(100vh - 100px);
  overflow-y: auto;
  position: relative; 
  height: 80vh; /* Defina a altura para ser 80% da altura da viewport */
  max-height: 90vh; /* Opcional, dependendo de se você quer que seja exatamente 80% ou no máximo 80% */
  overflow-y: auto;
}

.signs-in-detail::before {
  content: ''; /* Necessário para o pseudo-elemento ser gerado */
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5); /* Branco com 50% de opacidade */
  z-index: 0;
}

/* Assegure-se de que o conteúdo da div esteja acima do pseudo-elemento */
.signs-in-detail > * {
  position: relative;
  z-index: 1;
}

.logo {
  max-width: 150px;
}

.row.no-gutters.custom-background {
  padding-left: 0;
  padding-right: 0;
}

.carousel-item-content {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  height: 100%;
}

.carousel-image {
  max-height: 200px;
}

.carousel-title {
  font-size: 1.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
}

.carousel-description {
  color: #ccc;
  font-size: 1rem;
}

@media (max-width: 768px) {
  .carousel-image {
    max-height: 150px;
  }

  .carousel-title {
    font-size: 1.25rem;
  }

  .carousel-description {
    font-size: 0.9rem;
  }
}


</style>
